import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PageProps } from 'gatsby';

const PREVIEW_REFRESH_TIMEOUT = 60000;

const PreviewModeWrapper = styled.div`
  background-color: #f9f9f9;
  border: 1px solid #eee;
  border-radius: 15px;
  color: #333;
  font-size: 125%;
  font-weight: bold;
  margin: 0 auto;
  margin-top: 50px;
  padding: 10px;
  text-align: left;
  width: 60%;

  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);

  & > h1 {
    margin-left: 20px;
  }

  & > hr {
    border: 1px solid #ddd;
    margin: 0 20px;
  }

  & > ul {
    list-style-type: none;
    padding-left: 20px;
  }
`;

const Preview: React.FC<PageProps> = () => {
  if (typeof window === 'undefined') {
    return null;
  }

  const [isRedirecting, setIsRedirecting] = useState(false);
  const { protocol, host, search } = window.location;
  const params = new URLSearchParams(search);

  async function fetchLatestContent() {
    const response = await fetch(`${protocol}//${host}/__refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({}),
    });

    if (response.status === 200) {
      const contentfulId = params.get('id');
      const pageTypeId = params.get('pageTypeID');
      const locale = params.get('locale');

      setTimeout(() => {
        setIsRedirecting(true);
        window.location.href = `${protocol}//${host}/preview/redirect?id=${contentfulId}&pageTypeID=${pageTypeId}&locale=${locale}`;
      }, PREVIEW_REFRESH_TIMEOUT);
    }
  }

  useEffect(() => {
    fetchLatestContent();
  }, []);

  return (
    <PreviewModeWrapper>
      <h1>CMS Preview App</h1>
      <hr />
      {
        <ul>
          <li>{!isRedirecting ? '⛾' : '☑'} Refreshing data... (1 min)</li>
          <li>
            {isRedirecting ? '⛾' : '☐'} Redirecting to preview page... (1min)
          </li>
        </ul>
      }
    </PreviewModeWrapper>
  );
};

export default Preview;
